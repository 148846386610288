
const { toEthiopian } = require('ethiopian-date');

export function convertToEthiopian(dateString) {
    // Split the Gregorian date
    const [monthName, day, year] = dateString.split("/");
    const months = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
        Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
    };

    // Convert month name to number
    const month = months[monthName];

    // Convert Gregorian to Ethiopian
    const [ethYear, ethMonth, ethDay] = toEthiopian(
        parseInt(year, 10),
        parseInt(month, 10),
        parseInt(day, 10)
    );

    // Ethiopian month names in Amharic
    const ethMonthNames = [
        "መስከረም", "ጥቅምት", "ኅዳር", "ታኅሳስ", "ጥር", "የካቲት",
        "መጋቢት", "ሚያዝያ", "ግንቦት", "ሰኔ", "ሐምሌ", "ነሐሴ", "ጳጉሜን"
    ];

    const ethMonthName = ethMonthNames[ethMonth - 1];

    // Return the Ethiopian calendar date
    return `${ethMonthName}/${ethDay}/${ethYear}`;
}




export const gregorianToEthiopian = (gregorianDate) => {
    // const [month, day, year] = gregorianDate.split('/').map(Number);
    let _month = gregorianDate.split("/")[0];
    let _day = gregorianDate.split("/")[1];
    let _year = gregorianDate.split("/")[2];

    // Subtract 7 or 8 years from the Gregorian year
    let ethiopianYear = _year - 7 - (isLeapYear(_year) ? 1 : 0);

    // Add 1 to the Gregorian month
    const ethiopianMonth = _month + 1;

    // Keep the Gregorian day unchanged
    let ethiopianDay = _day;

    // If the Gregorian year is a leap year, subtract 1 day
    if (isLeapYear(_year)) {
        ethiopianYear--;
        ethiopianDay--;
    }

    // const ethiopianYear = Number(_year) - 8;
    // const ethiopianMonth = Number(_month) - 4;
    // const ethiopianDay = Number(_day) - 10;

    console.log('====================================');
    console.log(gregorianDate);
    console.log('====================================');
    console.log(`${ethiopianDay}/${ethiopianMonth}/${ethiopianYear}`)
    return `${ethiopianDay}/${ethiopianMonth}/${ethiopianYear}`;
    // return {
    //     year: ethiopianYear,
    //     month: ethiopianMonth,
    //     day: ethiopianDay,
    // };
}

export function formatDate(dateString) {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const [day, month, year] = dateString.split("/");
    const formattedDate = `${months[parseInt(month) - 1]}/${day}/${year}`;
    return formattedDate;
}

const result = formatDate("25/08/2022");
console.log(result); // Output: 25/Aug/2022


function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}