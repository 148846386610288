import React, { useEffect, useState } from "react";
import DocumentCard from "../../../components/cards/DocumentCard";
import { BsFillPersonFill } from "react-icons/bs";
import { IoReturnUpBack } from "react-icons/io5";
import CheckDocumentModal from "../../../components/utilities/CheckDocumentModal";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CTextArea, FormProvider } from "../../../components/Forms/Fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToaster } from "../../../hooks/useToaster";
import {
  VERIFY_EXPATRIATE_DOCUMENT,
  VERIFY_FINISH_PERMIT,
} from "../../../graph-query/mutation";
import { useLocalStore } from "../../../store";
import { BANK_SLIP_ID, BANK_SLIP_ID1, BANK_SLIP_ID2, BANK_SLIP_ID3, PROFILE_IMAGE_ID } from "../../../utils/constants";
import { MdPictureAsPdf, MdVerified } from "react-icons/md";
import { trackPromise } from "react-promise-tracker";
import { Can } from "../../../permission/Can";
import { roles } from "../../../utils/roles";
import {
  GetLatestProfilePic,
  checkArrayObjects,
  filterAndGroupByDocumentTypeAndCategory,
  isDocumentVerified,
  processDocuments,
} from "../../../utils/utilityFunctions";
import RejectModal from "../../../components/modals/RejectModal";
import PDFReader from "../../../components/pdf/PDFReader";
import DocumentViewerDrawer from "../../../components/modals/DocumentViewerDrawer";
import Tooltip from "../../../components/Tooltip";
import Moment from "react-moment";
import { GET_FILTERED_PERMIT_BYID, GET_WORK_PERMIT_NEW_BYID } from "../../../graph-query/queries";
import { WORK_PERMIT_TYPES } from "../../../utils/constants";
import { getImageUrl } from "../../../components/common/imageUtils";

const rejectSchema = yup
  .object({
    rejectSlip: yup.boolean().required(),
    message: yup.string().min(5).required(),
  })
  .required();

const CancellationDocumentView = () => {
  const [show, setShow] = useState(false);
  const [tabDisplay, setTabDisplay] = useState("APPLICATION");
  const [enableReject, setEnableReject] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [disableFinish, setDisableFinish] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showData, setShowData] = useState();
  const [recievedData, setRecievedData] = useState();
  const [recievednewData, setRecievedNewData] = useState();

  const { id } = useParams();

  const router = useNavigate();
  const toast = useToaster();

  const { currentRole, user, documentTypeCategories } = useLocalStore();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(rejectSchema),
  });

  const handleModal = (w) => {
    setShowData(w);
    setShow((prev) => !prev);
  };

  // const { data, loading } = useGraphQLQuery(GET_EXPATRIATE_BYID, { id: id });
  const [getData, { loading }] = useLazyQuery(GET_FILTERED_PERMIT_BYID, {
    variables: {
      permitID: id,
      permitType: WORK_PERMIT_TYPES.CANCELLATION,
    },
    onCompleted: (data) => {
      setRecievedData(data?.expatriate_work_permits[0]);
    },
  });
  const [getNewData] = useLazyQuery(GET_WORK_PERMIT_NEW_BYID, {
    variables: {
      emr_number: recievedData?.emr_number,
    },
    onCompleted: (data) => {
      setRecievedNewData(data);
    },
  });

  useEffect(() => {
    const handleClick = async()=>{
      await   trackPromise(getData());;
      await   trackPromise(getNewData());
    }
    handleClick()
  }, []);

  useEffect(() => {
    if (recievedData) {
      const allResolved = processDocuments(
        recievedData?.expatriate_work_permit_documents
      );
      setDisableFinish(allResolved);
      console.log("___test ", allResolved);
    }
  }, [recievedData, setRecievedData]);

  const extractUniqueTitles = (documents) => {
    const uniqueTitles = new Set();
    documents.forEach((doc) => {
      uniqueTitles.add(doc.title);
    });
    return Array.from(uniqueTitles);
  };

  const handleRejection = async (d) => {
    console.log("rejection is slip clicked!!", id, d);
    setRejectModal((prev) => !prev);
    // await reject_work_permit({
    //   variables: { id: showData?.id, verified: false, remark: d.message },
    // });
  };

  const handleFinish = (id) => {
    //validate if all document is verified

    const titleDoc = extractUniqueTitles(
      recievedData?.expatriate_work_permit_documents
    );

    if (titleDoc) {
      console.log(titleDoc);
      let res = [];
      titleDoc.map((item) => {
        const _value = isDocumentVerified(
          item,
          recievedData?.expatriate_work_permit_documents
        );
        res.push(_value);
      });
      console.log(res);

      const checkStatus = res.some((value) => value === true);

      if (checkStatus) {
        // console.log(application);
        console.log(checkStatus);
        finishReview({
          variables: {
            permitID: id,
            checkerStatus: true,
          },
          onCompleted: (d) => {
            toast.SuccessToast("Document Review Finish Successfully");
            getData({ fetchPolicy: "network-only" });
          },
          onError: (er) => {
            toast.ErrorToast("Unable to finish Document Review");
          },
        });
      } else {
        toast.WarnToast("Please Make sure all documents are Verified");
      }
    }
  };

  const [update_work_permit, { fin_data, loading: signInLoading }] =
    useMutation(VERIFY_EXPATRIATE_DOCUMENT, {
      onError(err) {
        const error = `${err}`.split(":").reverse()[0];
        console.log(error.trim());
      },
      onCompleted(d) {
        console.log(d);
        // getData();
      },
    });

  const [finishReview, {}] = useMutation(VERIFY_FINISH_PERMIT);

  const verifiyDocument = async () => {
    console.log("verify doc ID: ", selectedDocument?.id, true);
    await update_work_permit({
      variables: {
        id: selectedDocument?.id,
        review_status: true,
        verified_on: new Date(),
      },
    }).then(() => {
      toast.SuccessToast("Document Verified!");
      // handleModal(selectedDocument.id);
      setOpenDrawer(false);
      getData({ fetchPolicy: "network-only" });
    });
  };

  const handleReject = async (q) => {
    console.log("handle reject", q);
    await update_work_permit({
      variables: {
        id: selectedDocument?.id,
        review_note: q.review_note_doc,
        review_status: false,
      },
    }).then(() => {
      toast.WarnToast("Document Rejected!");
      // handleModal(selectedDocument.id);
      setRejectModal(false);
      setOpenDrawer(false);
      getData({ fetchPolicy: "network-only" });
    });
  };

  // const handleProfileImage = (c) => {
  //   console.log(FilteredDocument);
  //   return c?.expatriate_work_permits[0]?.expatriate_work_permit_documents
  //     ?.filter((e) => e?.document_type?.id === PROFILE_IMAGE_ID)
  //     ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
  //     .map((i) => i?.files)[0];
  // };

  /**
   * @description reject whole cancellation
   * @param {*} e
   */
  const handleRejectPermit = (e) => {
    console.log("reject permit: ", e);
    finishReview({
      variables: {
        permitID: recievedData?.expatriate_work_permits[0]?.id,
        checkerStatus: false,
        status: false,
        note: e?.review_note_doc,
      },
      onCompleted: () => {
        toast.SuccessToast("Work Permit Rejected!");
        setRejectModal(false);
      },
    });
  };

  const handleSelectDocument = (d) => {
    console.log("selected document: ", d);
    setOpenDrawer(!openDrawer);
    setSelectedDocument(d);
  };
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (GetLatestProfilePic(
        recievedData
      )) {
        const url = await getImageUrl(GetLatestProfilePic(
          recievedData
        ));
        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [GetLatestProfilePic(
    recievedData
  )]);
  const [imageUrlpp, setImageUrlpp] = useState("");

   useEffect(() => {
     const fetchImageUrl = async () => {
       if (GetLatestProfilePic(
        recievednewData
       )) {
         const url = await getImageUrl(GetLatestProfilePic(
      recievednewData
         ));
         setImageUrlpp(url);
       }
     };
 
     fetchImageUrl();
   }, [GetLatestProfilePic(recievednewData
   )]);
 
 
  const [imageUrls, setImageUrls] = useState({});

  // Fetch image URLs with fallback handling
  useEffect(() => {
    const fetchImageUrls = async () => {
      const urls = {};

      for (const item of recievedData?.expatriate_work_permit_documents || []) {
        const imageUrl = await getImageUrl(item.files);
        urls[item.files] = imageUrl;
      }
      
      setImageUrls(urls);
    };

    if (recievedData) {
      fetchImageUrls();
    }
  }, [recievedData]);

  
  return (
    <>
      <div>
        <div className="w-full bg-white p-4 rounded-md space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="h-full text-white rounded-md text-[5rem] bg-prime40/25 flex items-center justify-center p-2">
                <img
                  src={imageUrlpp}
                  className="w-36 h-36 object-cover"
                  alt=""
                />
              </div>
              <div className="flex flex-col capitalize">
                <h2 className=" font-semibold flex flex-wrap mb-1">
                  {`${recievedData?.expatriate?.first_name} ${
                    recievedData?.expatriate?.father_name
                  } ${
                    recievedData?.expatriate?.grand_father_name !== null
                      ? recievedData?.expatriate?.grand_father_name
                      : ""
                  }`}
                </h2>
                <p className=" text-sm font-medium mb-2">
                  {recievedData?.expatriate?.nationality?.name}
                </p>
                <span className="p-1 self-start rounded-md text-xs font-medium text-primary bg-prime40/40 border-primary border">
                  {recievedData?.expatriate_work_permit_type?.name} application
                </span>
                <small className="mt-2 font-normal">
                  Applied on:{" "}
                  <Moment format="YYYY-MM-DD">
                    {recievedData?.created_at}
                  </Moment>
                </small>
                <span>{recievedData?.emr_number}</span>
              </div>
            </div>
            <div className="w-1/4 2xl:w-1/5 space-y-2">
              <Can I={"action"} a={"documentCheck"}>
                {() => (
                  <button
                    disabled={!disableFinish}
                    onClick={() => handleFinish(recievedData?.id)}
                    className={`${
                      recievedData?.document_checker_status
                        ? "opacity-40 pointer-events-none"
                        : ""
                    } disabled:opacity-50 disabled:pointer-events-none bg-primary w-full text-white py-2 rounded`}
                  >
                    Finish
                  </button>
                )}
              </Can>
              <Can I={"action"} a={"rejectPermit"}>
                {() => (
                  <button
                    onClick={() => setRejectModal(true)}
                    className={`${
                      recievedData?.review_status === false
                        ? "opacity-40 pointer-events-none"
                        : ""
                    } bg-red w-full text-white py-2 rounded`}
                  >
                    Reject Permit
                  </button>
                )}
              </Can>
              <button
                onClick={() => router(-1)}
                className="border-primary w-full border flex items-center justify-center gap-2 text-primary py-2 rounded"
              >
                <IoReturnUpBack />
                <p>go back</p>
              </button>
            </div>

            {currentRole !== roles.WP_EMR_GENERATOR && (
              <div className="w-1/3 space-y-4">
                <input
                  onChange={() => setEnableReject(!enableReject)}
                  type="checkbox"
                  id="reject"
                  label="rejectSlip"
                />
                <label htmlFor="reject"> Reject Slip</label>
                <FormProvider onSubmithandler={handleSubmit(handleRejection)}>
                  <div className="bg-white p-3">
                    <CTextArea
                      label="Message"
                      name="message"
                      disabled={!enableReject}
                      placeholder="Here the reason for the rejection"
                      register={register}
                      errors={errors}
                      validation={{ required: true }}
                    ></CTextArea>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        disabled={!enableReject}
                        className="disabled:pointer-events-none disabled:cursor-not-allowed bg-N95/70 py-2 w-1/2 font-semibold text-N50"
                      >
                        reject
                      </button>
                    </div>
                  </div>
                </FormProvider>
              </div>
            )}
          </div>

          {recievedData &&
            recievedData?.expatriate_work_permit_documents?.map((item) => {
              return (
                <div className="bg-white border-2 border-gray-200 rounded-md overflow-hidden">
                  <div className="relative p-4 flex justify-between items-center">
                    <div className="flex gap-4">
                      <a
                        rel="noopener noreferrer"
                        onClick={() => handleSelectDocument(item)}
                        className="w-36 h-36 bg-gray-400 rounded-md flex justify-center items-center hover:opacity-60 cursor-pointer hover:scale-105"
                      >
                        {item?.files.split(".").pop() !== "pdf" ? (
                          <img
                            className="w-full h-full object-cover"
                            src={imageUrls[item.files]}
                            alt=""
                          />
                        ) : (
                          <div className="w-full h-full flex justify-center items-center">
                            <MdPictureAsPdf
                              size={30}
                              className="text-red-500"
                            />
                          </div>
                        )}
                      </a>
                      <div className="flex flex-col gap-3">
                        <h3 className="font-semibold capitalize text-lg">
                          {item?.title}
                        </h3>
                        <p>Category</p>
                        <small>
                          Document Type: {item?.files.split(".").pop()}
                        </small>
                        <span
                          className={`${
                            item?.review_status === true
                              ? "bg-green50"
                              : item?.review_status === false
                              ? "bg-red50"
                              : "bg-yellow50"
                          } text-center rounded w-fit text-gray-50 px-3 py-0.5`}
                        >
                          {item?.review_status === true
                            ? "Verified"
                            : item?.review_status === false
                            ? "Rejected"
                            : "Pending"}
                        </span>
                      </div>
                    </div>
                    {item?.reviewed_by_info && (
                      <div className="absolute bottom-2 right-2">
                        <Tooltip
                          tooltipsText={`${
                            item.review_status ? "Verified" : "Rejected"
                          } this document`}
                        >
                          <span className="bg-blue-100 flex gap-2 items-center rounded px-4 py-0.5">
                            <MdVerified />
                            {item?.reviewed_by_info?.name}
                          </span>
                        </Tooltip>
                      </div>
                    )}
                    {/* Hide Bank Slip Check Buttons for document checkers */}
                    {((item?.document_type?.id !== (BANK_SLIP_ID )) || (item?.document_type?.id !== ( BANK_SLIP_ID1) )|| (item?.document_type?.id !== ( BANK_SLIP_ID2)) || (item?.document_type?.id !== ( BANK_SLIP_ID3))) && (
                      <Can I={"action"} a={"rejectPermit"}>
                        {() => (
                          <div className="flex flex-col gap-3">
                            <button
                              type="button"
                              onClick={() => handleSelectDocument(item)}
                              className="px-5 py-1 rounded-md border-2 border-primary text-primary text-sm"
                            >
                              Check
                            </button>
                          </div>
                        )}
                      </Can>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {/* Drawer to view PDF */}
      {openDrawer && (
        <DocumentViewerDrawer
          selectedDocument={selectedDocument}
          handleVerify={verifiyDocument}
          handleRejection={handleRejection}
          handleCloseDrawer={() => setOpenDrawer(false)}
        />
      )}

      {rejectModal && (
        <RejectModal
          setShowDocumentModal={setRejectModal}
          title={"Reject Work permit"}
          handleRejectDocument={handleReject}
        />
      )}
    </>
  );
};

export default CancellationDocumentView;
