import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BsFillPersonFill,
  BsCalendarEvent,
  BsGlobe,
  BsMailbox,
} from "react-icons/bs";
import { BiFemale, BiMale, BiCategory, BiMoney } from "react-icons/bi";
import { GiPassport } from "react-icons/gi";
import { AiOutlineFile, AiOutlineMail, AiOutlineNumber } from "react-icons/ai";
import { FaCcVisa } from "react-icons/fa";
import FormLabel from "../../common/FormLabel";
// import { trackPromise } from "react-promise-tracker";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { UPLOAD_FILE } from "../../../graph-query/mutation";
import { IoCloudUploadOutline } from "react-icons/io5";
import { CInputField, CSelectField, COTPField, FormProvider } from "../Fields";
import CButton from "../CButton";
import { useLocalStore } from "../../../store";
import { OrganizationGeneralInfoSchema } from "../../../utils/SchemaValidation";
import useLocalStorageHash from "../../../hooks/useLocalStorage";
import {
  ExtractFileTypeAndNameFromBase64,
  base64ToData,
  formatDate,
  getBase64,
} from "../../../utils/utilityFunctions";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import ChooseDocumentModal from "../../utilities/ChooseDocumentModal";
import FileInput from "../Fields/FileInput";
import NoticeCard from "../../cards/NoticeCard";
import { PROFILE_IMAGE_ID, PROFILE_IMAGE_ID2, WorkPermitForms } from "../../../utils/constants";
import CustomFileSelect from "../../common/CustomFileSelect";
import UploadSingleFile from "../../uploads/UploadFile";
import useStepperFormStore from "../../../store/formStore";
import UploadAvatar from "../../uploads/avatar";

const GeneralForm = ({ handleFormSubmit, defaultValues }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [visaData, setVisaData] = useState(null);
  const [passportData, setPassportData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  // const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [tempFiles, setTempFile] = useState([]);

  const fileInputRef = useRef();
  // Create refs for each input field
  const inputRefs = useRef(
    Array(10)
      .fill(null)
      .map(() => React.createRef())
  );
  // const visaRefs = useRef([]);
  const visaRefs = useRef(
    Array(10)
      .fill(null)
      .map(() => React.createRef())
  );

  const values = inputRefs.current.map((ref) => ref?.current?.value);
  const valuesVi = visaRefs.current.map((ref) => ref?.current?.value);

  // console.log(values, valuesVi);

  const {
    visaTypes,
    martialTypes,
    documentTypes,
    occupationCatagory,
    nationality,
    genders,
    expatriateWayEntered,
    data,
    fileUploadAttributes,
    setData,
  } = useLocalStore();

  const { loading } = useStepperFormStore();

  console.log("__general: ", defaultValues);
  console.log("__general: ", formatDate(defaultValues?.date_of_birth));

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(OrganizationGeneralInfoSchema),
    defaultValues: {
      ...defaultValues,
      date_of_birth: formatDate(defaultValues?.date_of_birth),
      passport_expiry_date: formatDate(defaultValues?.passport_expiry_date),
      passport_issued_date: formatDate(defaultValues?.passport_issued_date),
      visa_issued_date: formatDate(defaultValues?.visa_issued_date),
      visa_expiry_date: formatDate(defaultValues?.visa_expiry_date),
    },
    // visa_issued_date: defaultValues?.visa_issued_date?.split("T")[0],
  });
  // resolver: yupResolver(OrganizationGeneralInfoSchema),

  useEffect(() => {
    if (defaultValues) {
      setTempFile(defaultValues?.expatriate_work_permit_documents);
      setPreviewImage(
        defaultValues?.expatriate_work_permit_documents
          ?.filter((i) => ((i?.document_type_id === (PROFILE_IMAGE_ID))|| (i?.document_type_id === (PROFILE_IMAGE_ID2))))
          ?.map((i) => i?.file)[0]
      );
      // base64ToData(
      //   defaultValues?.expatriate_work_permit_documents
      //     ?.filter((i) => i?.document_type_id === PROFILE_IMAGE_ID)
      //     ?.map((i) => i?.file)[0]
      // );
    }
    console.log("tem", tempFiles);
  }, [defaultValues]);

  const handleVisaFile = (file) => {
    // const file = e;
    if (file) {
      getBase64(file).then((result) => {
        let fileType = (file?.type).split("/").length
          ? (file?.type).split("/")[1]
          : "";
        setTempFile((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            fileType: fileType,
            name: file.name,
            document_type_id: "ad702374-171c-4d4b-b3f6-161170e3db0b",
            catagory: "51ff2b29-4d56-449c-a69d-08348d7299b4",
            title: "Visa",
          },
        ]);
      });
    }
    // if (file) {
    //   filesUpload(file, (filePath) =>
    //     // setTempFile([...tempFiles, filePath, "profile"])
    //     setTempFile((prevFiles) => [
    //       ...prevFiles,
    //       {
    //         ...filePath,
    //         document_type_id: "ad702374-171c-4d4b-b3f6-161170e3db0b",
    //         catagory: "51ff2b29-4d56-449c-a69d-08348d7299b4",
    //         title: "Visa",
    //       },
    //     ])
    //   );
    // }
  };

  const handlePassFile = async (file) => {
    // const file = e;
    // console.log("passport ", file);
    if (file) {
      getBase64(file).then((result) => {
        let fileType = (file?.type).split("/").length
          ? (file?.type).split("/")[1]
          : "";
        setTempFile((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            fileType: fileType,
            name: file.name,
            document_type_id: "56de0cbc-7986-4411-8a35-aba426181a07",
            catagory: "51ff2b29-4d56-449c-a69d-08348d7299b4",
            title: "Passport",
          },
        ]);
      });
    }
  };

  const handleProfileImage = (file) => {
    if (file) {
      getBase64(file).then((result) => {
        let fileType = (file?.type).split("/").length
          ? (file?.type).split("/")[1]
          : "";
        setTempFile((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            fileType: fileType,
            name: file.name,
            document_type_id: "cde354c9-bece-43f2-9739-72e0c7892553",
            catagory: "9169dfa0-5c5d-4e89-a7bc-ccbcb5a52ff3",
            title: "Profile Picture",
          },
        ]);
      });
    }
  };
  // ===========================================================================================

  /**
   * @description handle modal file upload
   */
  const handleFileUpload = async () => {
    // console.log("file Upload modal clickup", files);
    files.map((file) => {
      getBase64(file._file).then((result) => {
        let fileType = (file._file?.type).split("/").length
          ? (file._file?.type).split("/")[1]
          : "";
        setTempFile((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            fileType: fileType,
            name: file._file.name,
            document_type_id: file.document_type_id,
            catagory: file.catagory,
            title: checkArray(file.title),
          },
        ]);
      });
    });
    // await files.map((i) => {
    //   filesUpload(
    //     i?._file,
    //     (filePath) =>
    //       setTempFile((prevFiles) => [
    //         ...prevFiles,
    //         {
    //           ...filePath,
    //           title: checkArray(i?.title),
    //           document_type_id: i.document_type_id,
    //           catagory: i.catagory,
    //         },
    //       ])
    //   );
    // });
    setUploadModal(false);
  };

  const checkArray = (value) => {
    if (Array.isArray(value)) {
      value = value.join("");
    }
    return value;
  };

  /**
   * @description submit form
   * @param {*} data
   */
  const onSubmit = async (data) => {
    // const values = getValues();
    // console.log(values.passport_number);
    // console.log(data);
    //console.log(tempFiles);
    if (!tempFiles?.some((i) => i?.title === "Profile Picture")) {
      toast.warn("Please Upload Profile Image");
    } else if (!tempFiles?.some((i) => i?.title === "Visa")) {
      toast.warn("Please Upload Visa Document");
    } else if (!tempFiles?.some((i) => i?.title === "Passport")) {
      toast.warn("Please Upload Passport Document");
    } else {
      let _res = {
        ...data,
        expatriate_work_permit_documents: tempFiles,
      };
      // handleFormSubmit(_res);
      // setLoading(true)
      handleFormSubmit(WorkPermitForms.GENERAL_INFORMATION, _res);
      // setTimeout(()=>{
      //   setLoading(false)
      // },4000)
    }
    // console.log("otp: ", data.passport_number, data.visa_number);
    // setData("expatriate", _res);
    // moveToNext();
  };

  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarError, setAvatarError] = useState(false);

  const handleAvatarDrop = (acceptedFiles, fileRejections) => {
    // Handle the dropped files and rejections
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      handleProfileImage(file);
      console.log("__recieved file: ", file);
      createPreview(file).then((_preview) => {
        setAvatarFile({
          file,
          preview: _preview,
        });
        setAvatarError(false);
      });
    } else if (fileRejections.length > 0) {
      setAvatarFile(null);
      setAvatarError(true);
    }
  };

  /**
   * @description create a preview'able image
   * @param {*} file
   * @returns
   */
  const createPreview = (file) => {
    return new Promise((resolve, reject) => {
      if (!file || !file.type.startsWith("image/")) {
        setAvatarError("Invalid file type. Only image files are allowed.");
        // reject(new Error("Invalid file type. Only image files are allowed."));
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <div className="flex flex-col gap-4 p-4 items-start h-full w-full">
        {uploadModal && (
          <ChooseDocumentModal
            optionData={documentTypes
              .filter((i) => i?.document_type_category?.code === "APPLICATION")
              .map((i) => i)}
            files={files}
            setFiles={setFiles}
            onFileUpload={handleFileUpload}
            handleCancel={() => setUploadModal(false)}
          />
        )}

        <div className="flex flex-col gap-1 items-start pb-10">
          <h1 className="text-3xl font-bold text-N40">
            Expatriate's Personal Information
          </h1>
          <p className="text-xs font-light w-full lg:w-2/3 text-N60">
            Organization must fulfill the expatriates personal information in
            order to create the work permit process.
          </p>
        </div>

        <div className="w-full">
          <FormProvider onSubmithandler={handleSubmit(onSubmit)}>
            <div className="flex lg:flex-row flex-col mt-5 gap-4 items-start lg:items-center">
              <FormLabel
                labelIcon={<BsFillPersonFill />}
                labelTitile="Profile Picture"
                labelDescription="Enter applicants Profile Picture, once you upload the file you will review it on the avatar."
              />
              <div className="w-full lg:w-[75%] flex flex-wrap md:flex-nowrap gap-10 items-center">
                <UploadAvatar
                  file={avatarFile}
                  caption={""}
                  onDrop={handleAvatarDrop}
                  error={avatarError}
                />
                {/* <CustomFileSelect /> */}
                {/* <FileInput
                  enablePreview={true}
                  type={"image"}
                  defaultValue={
                    defaultValues &&
                    ExtractFileTypeAndNameFromBase64(
                      defaultValues?.expatriate_work_permit_documents
                        ?.filter((i) => i?.title === "Passport")
                        ?.map((i) => i)[0]?.file,
                      "profile",
                      "image"
                    )
                  }
                  onChange={handleProfileImage}
                /> */}
              </div>
            </div>

            {/* Applicants Name  */}
            <div className="flex lg:flex-row flex-col mt-5 gap-4 items-start lg:items-center">
              <FormLabel
                labelIcon={<BsFillPersonFill />}
                labelTitile="Applicants Name"
                labelDescription="Enter Full Name"
              />

              <div className="w-full lg:w-2/3">
                <CInputField
                  name="first_name"
                  register={register}
                  errors={errors}
                  defaultValue={data?.first_name}
                  validation={{ required: "First Name is required" }}
                  type="text"
                  label=""
                  placeholder="First Name"
                />
              </div>
              <div className="w-full lg:w-2/3">
                <CInputField
                  name="father_name"
                  register={register}
                  errors={errors}
                  defaultValue={data?.middle_name}
                  validation={{ required: "Father Name is required" }}
                  type="text"
                  label=""
                  placeholder="Father Name"
                />
              </div>
              <div className="w-full lg:w-2/3">
                <CInputField
                  name="grand_father_name"
                  register={register}
                  errors={errors}
                  defaultValue={data?.father_name}
                  validation={{ required: "GradFather Name is required" }}
                  type="text"
                  label=""
                  placeholder="Grad Father Name"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center w-full py-6">
              {/* Applicants Gender */}
              <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center ">
                <FormLabel
                  labelIcon={
                    <div className="flex">
                      <BiMale /> <BiFemale />
                    </div>
                  }
                  labelTitile="Gender"
                  labelDescription="Specify the gender of the expatriate"
                />
                <div className="flex gap-6 py-2">
                  <ul class="grid w-full gap-6 grid-cols-2">
                    <li>
                      <input
                        {...register("gender")}
                        id="gender M"
                        name="gender"
                        value="e739eaf0-603e-4b45-8ff8-9dd691442626"
                        type="radio"
                        class="hidden peer"
                      />
                      <label
                        htmlFor="gender M"
                        class="inline-flex items-center justify-center w-full px-4 py-1 text-gray-500 bg-white border border-gray-200 rounded cursor-pointer peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-gray-100"
                      >
                        Male
                      </label>
                    </li>
                    <li>
                      <input
                        {...register("gender")}
                        id="gender F"
                        name="gender"
                        value="fbbf0923-b355-4c14-b584-aff55658b271"
                        type="radio"
                        class="hidden peer"
                      />
                      <label
                        htmlFor="gender F"
                        class="inline-flex items-center justify-center w-full px-4 py-1 text-gray-500 bg-white border border-gray-200 rounded cursor-pointer peer-checked:border-red/70 peer-checked:text-red/70 hover:text-gray-600 hover:bg-gray-100"
                      >
                        Female
                      </label>
                    </li>
                  </ul>
                  <p className="text-sm text-red mt-2">
                    {errors.gender?.message}
                  </p>
                </div>
              </div>
              {/* Applicants Date Of Birth  */}
              <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center justify-between">
                <FormLabel
                  labelIcon={<BsCalendarEvent />}
                  labelTitile="Date of Birth"
                  labelDescription="spacify the Bitrh date in GC."
                />
                <div className="w-full lg:w-2/3">
                  <CInputField
                    name="date_of_birth"
                    register={register}
                    errors={errors}
                    defaultValue={data?.date_of_birth}
                    validation={{ required: "Date of birth is required" }}
                    type="date"
                    label=""
                    placeholder="Birth Date"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center w-full py-6">
              {/* Applicants Nationality  */}
              <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center ">
                <FormLabel
                  labelIcon={<BsGlobe />}
                  labelTitile="Nationality"
                  labelDescription="specify the nationality of the expatriate"
                />

                <div className="w-full lg:w-2/3">
                  <CSelectField
                    name="nationality_id"
                    register={register}
                    errors={errors}
                    defaultValue={data?.nationality}
                    validation={{ required: false }}
                    options={nationality?.map((item) => {
                      return {
                        ...item,
                        value: item.id,
                      };
                    })}
                    label=""
                    placeholder="Nationality"
                  />
                </div>
              </div>

              {/* Applicants Martial Status  */}
              <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center justify-between">
                <FormLabel
                  labelIcon={
                    <div className="flex">
                      <BiMale /> <BiFemale />
                    </div>
                  }
                  labelTitile="Martial Status"
                  labelDescription="Specify the martial status of the expatriate"
                />
                <div className="w-full lg:w-2/3">
                  <CSelectField
                    name="marital_status"
                    register={register}
                    errors={errors}
                    defaultValue={data?.marital_status}
                    validation={{ required: "Martial Status is required" }}
                    options={martialTypes?.map((item) => {
                      return {
                        ...item,
                        name: item.name,
                        value: item.id,
                      };
                    })}
                    label=""
                    placeholder="Martial Status"
                  />
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <h2 className="md:text-2xl font-bold text-N40">
              Passport and Visa Information
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center w-full py-6">
              {/* Passport Number  */}
              <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center justify-between ">
                <FormLabel
                  labelIcon={<GiPassport />}
                  labelTitile="Passport Number"
                  labelDescription="Type applicant’s passport number. Based on your country info max Passport number should be 8"
                />
                <div className="w-full lg:w-2/3">
                  <CInputField
                    name="passport_number"
                    type="text"
                    fields={10}
                    errors={errors}
                    validation={{ required: false }}
                    inputRefs={inputRefs}
                    register={register}
                    watch={watch}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center w-full py-6">
              {/* Passport issued date */}
              <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center justify-between">
                <FormLabel
                  labelIcon={<BsCalendarEvent />}
                  labelTitile="Passport Issued Date"
                  labelDescription="Specify the passport issued date of the expatriate"
                />
                <div className="w-full lg:w-2/3">
                  <CInputField
                    name="passport_issued_date"
                    register={register}
                    errors={errors}
                    defaultValue={data?.passport_issued_date}
                    validation={{ required: "Passport issue date is required" }}
                    type="date"
                    label=""
                    placeholder="Passport Issued Date"
                  />
                </div>
              </div>

              {/* Passport Expire date */}
              <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center justify-between">
                <FormLabel
                  labelIcon={<BsCalendarEvent />}
                  labelTitile="Passport Expire Date"
                  labelDescription="Specify the passport expire date of the expatriate"
                />
                <div className="w-full lg:w-2/3">
                  <CInputField
                    name="passport_expiry_date"
                    register={register}
                    errors={errors}
                    defaultValue={data?.passport_expiry_date}
                    validation={{
                      required: "passport expire date is required",
                    }}
                    type="date"
                    label=""
                    placeholder="Passport Expiry Date"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center w-full py-6">
              {/* Visa Number  */}
              <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center justify-between ">
                <FormLabel
                  labelIcon={<FaCcVisa />}
                  labelTitile="Visa Number"
                  labelDescription="Enter your visa number"
                />
                <div className="w-full lg:w-2/3">
                  <CInputField
                    name="visa_number"
                    type="text"
                    fields={10}
                    errors={errors}
                    validation={{ required: false }}
                    inputRefs={visaRefs}
                    register={register}
                    watch={watch}
                  />
                  {/* <COTPField
                      name="visa_number"
                      type="text"
                      fields={10}
                      errors={errors}
                      validation={{ required: false }}
                      inputRefs={visaRefs}
                      register={register}
                      watch={watch}
                    /> */}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center w-full py-6">
              {/* Visa issued date */}
              <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center ">
                <FormLabel
                  labelIcon={<BsCalendarEvent />}
                  labelTitile="Visa Issued Date"
                  labelDescription="Spacify the vissa Issued date of the expatrait"
                />
                <div className="w-full lg:w-2/3">
                  <CInputField
                    name="visa_issued_date"
                    register={register}
                    errors={errors}
                    defaultValue={data?.visa_issued_date}
                    validation={{ required: "visa issue date is required" }}
                    type="date"
                    label=""
                    placeholder="Visa Issued Date"
                  />
                </div>
              </div>

              {/* Visa Expire date */}
              <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center justify-between">
                <FormLabel
                  labelIcon={<BsCalendarEvent />}
                  labelTitile="Visa Expire Date"
                  labelDescription="Spacify the visa expire date of the expatrait"
                />
                <div className="w-full lg:w-2/3">
                  <CInputField
                    name="visa_expiry_date"
                    register={register}
                    errors={errors}
                    defaultValue={data?.visa_expiry_date}
                    validation={{ required: "visa expire date is required" }}
                    type="date"
                    label=""
                    placeholder="Visa Expiry Date"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center w-full py-6">
              <div className="flex lg:flex-col flex-col gap-4 items-start justify-between w-full">
                <FormLabel
                  labelIcon={<AiOutlineFile />}
                  labelTitile="Upload Visa Files"
                  labelDescription="Upload your visa in pdf format"
                />
                <div className="w-full ">
                  <UploadSingleFile
                    type={"file"}
                    defaultValue={
                      defaultValues &&
                      ExtractFileTypeAndNameFromBase64(
                        defaultValues?.expatriate_work_permit_documents
                          ?.filter((i) => i?.title === "Visa")
                          ?.map((i) => i)[0]?.file
                      )
                    }
                    onChange={(e) => handleVisaFile(e)}
                  />

                  {/* <FileInput
                    type={"file"}
                    defaultValue={
                      defaultValues &&
                      ExtractFileTypeAndNameFromBase64(
                        defaultValues?.expatriate_work_permit_documents
                          ?.filter((i) => i?.title === "Visa")
                          ?.map((i) => i)[0]?.file
                      )
                    }
                    onChange={(e) => handleVisaFile(e)}
                  /> */}
                </div>
              </div>
              <div className="flex lg:flex-col flex-col gap-4 items-start justify-between w-full">
                <FormLabel
                  labelIcon={<AiOutlineFile />}
                  labelTitile="Upload Passport Files"
                  labelDescription="Upload your Passport in a pdf format"
                />
                <div className="w-full">
                  <UploadSingleFile
                    type={"file"}
                    defaultValue={
                      defaultValues &&
                      ExtractFileTypeAndNameFromBase64(
                        defaultValues?.expatriate_work_permit_documents
                          ?.filter((i) => i?.title === "Passport")
                          ?.map((i) => i)[0]?.file
                      )
                    }
                    onChange={(e) => handlePassFile(e)}
                  />
                  {/* <FileInput
                    type={"file"}
                    defaultValue={
                      defaultValues &&
                      ExtractFileTypeAndNameFromBase64(
                        defaultValues?.expatriate_work_permit_documents
                          ?.filter((i) => i?.title === "Passport")
                          ?.map((i) => i)[0]?.file
                      )
                    }
                    onChange={(e) => handlePassFile(e)}
                  /> */}
                </div>
              </div>
            </div>

            {/* Visa Type */}
            <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center ">
              <FormLabel
                labelIcon={<BiCategory />}
                labelTitile="Visa Type"
                labelDescription="Spacify the visa type of the expatrait"
              />
              <div className="w-full lg:w-2/3">
                <CSelectField
                  name="visa_type"
                  register={register}
                  errors={errors}
                  defaultValue={data?.visa_type}
                  validation={{ required: "visa type is required" }}
                  options={visaTypes?.map((item) => {
                    return {
                      ...item,
                      value: item?.id,
                    };
                  })}
                  label=""
                  placeholder="Visa Type"
                />
              </div>

              <FormLabel
                labelIcon={<BiCategory />}
                labelTitile="The way that the expatriate person is entered *"
                labelDescription="Spacify the visa type of the expatrait"
              />
              <div className="w-full lg:w-2/3">
                <CSelectField
                  name="way_of_entry"
                  register={register}
                  errors={errors}
                  defaultValue={data?.first_name}
                  validation={{
                    required: "Expatriate person entered visa type is required",
                  }}
                  options={expatriateWayEntered?.map((item) => {
                    return {
                      ...item,
                      value: item.id,
                    };
                  })}
                  label=""
                  placeholder="Visa Type"
                />
              </div>
            </div>

            <hr className="border-b-2 border-gray-400 my-5 border-opacity-10" />

            <div className="w-full flex-wrap sm:flex-nowrap flex gap-10 py-6">
              <FormLabel
                labelIcon={<AiOutlineFile />}
                labelTitile="File Upload"
                labelDescription="Upload applicant work information"
              />

              <div className="relative py-6 px-10 flex flex-col items-center justify-center gap-1 border border-dashed border-N95 cursor-pointer w-full md:w-[55%]">
                <IoCloudUploadOutline className="text-4xl text-primary font-light" />
                <p className="text-xs text-N95">
                  Drag Expatriate Passport in Pdf Format
                </p>

                <div
                  onClick={() => setUploadModal(true)}
                  className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
              </div>
            </div>

            {/* <div className="w-full h-[200px] object-cover">
              <UploadSingleFile
                maxSize={3145728}
                accept="image/*"
                file={previewImage?.cover}
                onDrop={handleDrop}
              />

            </div> */}

            <hr className="my-4" />
            {/* nav */}
            <div className="w-full flex justify-end py-10">
              <CButton
                type="submit"
                loading={loading}
                btnLabel="Continue"
                classes="bg-primary block w-full"
              />
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default GeneralForm;
