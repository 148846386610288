import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  APPROVED_EXPATIATES_PERMITS_RENEWAL_FILTER,
  COUNT_NOT_ASSIGNED_PERMITS,
  DOCUMENT_ALL_APPROVED_WORK_PERMITS,
  EXPAT_BY_ORGANZATION,
  GET_ALL_ASSIGNED_WORK_PERMITS,
  GET_ALL_ORGANIZATION,
  GET_ASSIGNED_ORGANIZATION_LIST,
  GET_FILTERED_PERMIT,
  GET_FILTERED_PERMIT_EMR,
  GET_NOT_ASSIGNED_FILTERED_PERMIT,
  GET_SEARCH_ASSIGNED_WORK_PERMITS,
  GET_WORK_PERMIT_FILTERED,
  SEARCH_EXPATIATES_PERMITS_FILTER_DOCUMENT_CHECKER_FINANCE,
  SEARCH_EXPATIATES_PERMITS_RENEWAL_FILTER_DOCUMENT_CHECKER,
  SEARCH_WORK_PERMITS_FILTER,
  TOTAL_ASSIGNED_WORK_PERMIT,
  TOTAL_ASSIGNED_WORK_PERMIT_ALL,
  TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
  TOTAL_EXPAT_COUNT,
  TOTAL_PERMIT_EXPAT_COUNT,
} from "../../graph-query/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import BlackListModal from "../../components/utilities/BlackListModal";
import { useLocalStore } from "../../store";
import Table from "../../components/Table/CTable";
import { toast } from "react-toastify";
import authorize from "../../hooks/withAuthorization";
import {
  ASSIGN_WORK_PERMIT,
  GENERATE_CANCELATION_PAPER,
  VERIFY_EXPATRIATE_DOCUMENT,
} from "../../graph-query/mutation";
import { BANK_SLIP_ID, BANK_SLIP_ID1, BANK_SLIP_ID2, BANK_SLIP_ID3, WORK_PERMIT_TYPES } from "../../utils/constants";
import { roles } from "../../utils/roles";
import { FailedColumns } from "../../datasets/failed/FailedColumns";
import { CInputField, FormProvider } from "../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import CButton from "../../components/Forms/CButton";
import { useCancellationStore } from "../../store/cancellationStore";
import { Can } from "../../permission/Can";
import FilterBox from "../../components/Forms/FilterBox";
import DocumentViewerDrawer from "../../components/modals/DocumentViewerDrawer";
import RejectModal from "../../components/modals/RejectModal";

const Cancelled = () => {
  const [showModal, setShowModal] = useState(false);
  const [rejectedModal, setRejectModal] = useState(false);
  const [showEMRModal, setShowEMRModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [queryName, setQueryName] = useState();

  const [organizationData, setOrganizationData] = useState([]);
  const [assignedOrgList, setAssignedOrgList] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [totalAssignedPermit, setTotalAssignedPermit] = useState(0);
  const [totalExpatList, setTotalExpatList] = useState(0);
  // const [loading, setLoading] = useState(false);
  const [loadingRows, setLoadingRows] = useState([]); //for assign loading button
  const [show, setShow] = useState(false);
  const [currentTab, setCurrentTab] = useState("not_assigned");
  const [selectedTab, setSelectedTab] = useState("pending");
  const [allassignedPermit, setAllAssignedPermit] = useState([]);
  const [verifiedCancelledDoc, setVerifiedCancelledDoc] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const navigate = useNavigate();

  const [assign, {}] = useMutation(ASSIGN_WORK_PERMIT, {
    variables: {
      roles: [roles.WP_DOC_CHECKER, roles.WP_FINANCE],
    },
    onCompleted: (res) => {
      if (res?.assignWorkPermitReviewer?.error) {
        toast.error(res?.assignWorkPermitReviewer.error);
      } else {
        toast.success("Work Permit Assigned successfully");
      }
    },
    onError: (er) => toast.error("Error assigning work permit"),
  });
  //==========================================================
  const {
    offset,
    limit,
    setLimit,
    setOffset,
    setWorkPermits,
    filterOptionTable,
    workPermits,
    assginedWorkPermits,
    currentRole,
  } = useLocalStore();

  const {
    pending_limit,
    rejected_limit,
    approved_limit,
    pending_offset,
    rejected_offset,
    approved_offset,
    approvedCancellationList,
    rejectedCancellationList,
    pendingCancellationList,
    approvalCount,
    rejectedCount,
    pendingCount,
    loading,
    setLoading,
    setCountApproval,
    setCountPending,
    setCountRejected,
    setPendingLimit,
    setRejectedLimit,
    setApprovedLimit,
    setPendingOffset,
    setRejectedOffset,
    setApprovedOffset,
    setPendingList,
    setRejectedList,
    setApprovedList,
  } = useCancellationStore();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  const [generatePaper, { loading: letterLoading }] = useMutation(
    GENERATE_CANCELATION_PAPER
  );

  /**
   * @description search by EMR number
   */
  const [searchPermitEMR, {}] = useLazyQuery(
    APPROVED_EXPATIATES_PERMITS_RENEWAL_FILTER,
    {
      onCompleted: (d) => {
        setLoading(false);
        setApprovedList(d?.expatriate_work_permits);
      },
      onError: (er) => {
        console.log(er);
        setLoading(false);
      },
    }
  );
  /**
   * @access DOCUMENT CHECKER
   */
  const [searchPermitDocumentChecker, {}] = useLazyQuery(
    SEARCH_EXPATIATES_PERMITS_RENEWAL_FILTER_DOCUMENT_CHECKER,
    {
      onCompleted: (d) => {
        setPendingList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => console.log(er),
    }
  );

  /**
   * @description all expatrates for desk handler
   * @access desk:handler
   */
  const [getDataFromServer, { error }] = useLazyQuery(GET_FILTERED_PERMIT, {
    variables: {
      permitType: WORK_PERMIT_TYPES.CANCELLATION,
      limit: approved_limit,
      offset: approved_offset * approved_limit,
    },
    context: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    onCompleted: (data) => {
      console.log("___", data);
      setLoading(false);
      setApprovedList(data?.expatriate_work_permits);
      // setWorkPermits(data?.expatriate_work_permits);
    },
  });
  /**
   * @description get Not assigned renewal permits
   */
  const [getNotAssignedRenewalPermit, {}] = useLazyQuery(
    GET_NOT_ASSIGNED_FILTERED_PERMIT,
    {
      variables: {
        limit: Number(pending_limit),
        offset: pending_offset * Number(pending_limit),
        permitType: WORK_PERMIT_TYPES.CANCELLATION,
      },
      onCompleted: (res) => {
        setPendingList(res?.expatriate_work_permits);
      },
    }
  );

  // ===========================================EMR====================================
  /**
   * @description all requested cancellation permit for emr whom verifed by document handler
   * @access desk:handler
   */
  const [getVerifiedDocuments, { loading: emrLoading, error: emrError }] =
    useLazyQuery(GET_FILTERED_PERMIT_EMR, {
      variables: {
        permitType: WORK_PERMIT_TYPES.CANCELLATION,
        documentChecker: true,
        limit: approved_limit,
        offset: approved_offset * approved_limit,
      },
      context: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      onCompleted: (data) => {
        console.log("___", data);
        setLoading(false);
        // setVerifiedCancelledDoc(data?.expatriate_work_permits);
        setApprovedList(data?.expatriate_work_permits);
      },
    });

  const [getTotalAssignedPermitsAll, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_ALL,
    {
      variables: {
        permit: WORK_PERMIT_TYPES.CANCELLATION,
      },
      onCompleted: (d) => {
        setCountApproval(
          d?.expatriate_work_permits_aggregate?.aggregate?.count
        );
      },
    }
  );

  const [countExpat, {}] = useLazyQuery(TOTAL_PERMIT_EXPAT_COUNT, {
    variables: {
      permitType: WORK_PERMIT_TYPES.CANCELLATION,
    },
    onCompleted: (d) => {
      setCountApproval(d?.expatriate_work_permits_aggregate?.aggregate?.count);
      // setTotalExpatList(d?.expatriate_work_permits_aggregate?.aggregate?.count);
    },
  });
  const [countNotAssigned, {}] = useLazyQuery(COUNT_NOT_ASSIGNED_PERMITS, {
    variables: {
      permitType: WORK_PERMIT_TYPES.CANCELLATION,
    },
    onCompleted: (d) => {
      setCountPending(d?.expatriate_work_permits_aggregate?.aggregate?.count);
    },
  });

  /**
   * @description get Total count for assigned user
   * @access DOC:checker | finance
   */
  // const [getTotalAssignedPermits, {}] = useLazyQuery(
  //   TOTAL_ASSIGNED_WORK_PERMIT,
  //   {
  //     variables: {
  //       employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
  //     },
  //     onCompleted: (d) => {
  //       setTotalAssignedPermit(
  //         d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
  //       );
  //     },
  //   }
  // );

  /**
   * @description get permit for assigned user
   * @access doc:Cheker | finance
   */
  // const [getAllAssignedPermits, { loading: permitLoading }] = useLazyQuery(
  //   GET_ALL_ASSIGNED_WORK_PERMITS,
  //   {
  //     variables: {
  //       limit: limit,
  //       offset: offset * limit,
  //       permit: WORK_PERMIT_TYPES.CANCELLATION,
  //       employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
  //     },
  //     onCompleted: (d) => {
  //       setAllAssignedPermit(d?.assigned_workpermit_reviewers);
  //     },
  //     onError: (er) => {
  //       console.log(er);
  //     },
  //   }
  // );

  const [allOrg, {}] = useLazyQuery(GET_ALL_ORGANIZATION, {
    variables: {
      limit: 1000,
      offset: 0,
    },
    onCompleted: (_data) => {
      setOrganizationData(_data?.organizations);
    },
  });

  const [getAssignedOrg, {}] = useLazyQuery(GET_ASSIGNED_ORGANIZATION_LIST, {
    variables: {
      empID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
    },
    onCompleted: (d) => {
      setAssignedOrgList(d.assigned_organization_reviewers);
    },
  });

  //request new data if not found
  useEffect(() => {
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        console.log("getting data from server....");
        allOrg();
        getDataFromServer();
        getNotAssignedRenewalPermit();
        countExpat();
        countNotAssigned();
        break;

      case roles.WP_DOC_CHECKER:
        // getTotalAssignedPermits();
        getAllAssignedPermits();
        getAllApprovedPermits();
        getAllRejectedPermits();
        getTotalAssignedPermits();
        getTotalAssignedPermitsApproved();
        getTotalAssignedPermitsRejected();
        getAssignedOrg();
        break;

      case roles.WP_EMR_GENERATOR:
        setLoading(true);
        getVerifiedDocuments();
        getTotalAssignedPermitsAll();
        break;

      case roles.WP_FINANCE:
        getTotalAssignedPermits();
        getAllAssignedPermits();
        allOrg();
        break;

      default:
        // getDataFromServer();
        break;
    }
  }, [currentRole]);
  // =========================================================================

  const handleBankSlip = (c) => {
    console.log(c?.expatriate_work_permit_documents[0])
    // console.log("_ bank", c);
    const reviewedDocument = c?.expatriate_work_permit_documents
      ?.filter(
        (e) =>
          ((e?.document_type?.id === (BANK_SLIP_ID)) || (e?.document_type?.id === ( BANK_SLIP_ID1)) || (e?.document_type?.id === (BANK_SLIP_ID2) ) ||  (e?.document_type?.id === (BANK_SLIP_ID3) ) )&& e?.review_status === true
      )
      ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))[0];


      console.log(reviewedDocument,"reviewedDocument")
    if (reviewedDocument) {
      return reviewedDocument;
    }

    return c?.expatriate_work_permit_documents
    ?.filter((i) => ((i?.document_type?.id === (BANK_SLIP_ID )) || (i?.document_type?.id === (BANK_SLIP_ID1 )) || (i?.document_type?.id === ( BANK_SLIP_ID2)) || (i?.document_type?.id === ( BANK_SLIP_ID3))))?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
      .map((i) => i)[0];
  };

  const handleClose = () => {
    setShowModal(false);
    setRejectModal(false);
    //get applicant data
    getAllAssignedPermits({ fetchPolicy: "network-only" });
  };

  const handlePopUp = (e) => {
    console.log("test pop up clicked!", e);
    setShow((prev) => !prev);
    setQueryName(e);
  };

  const handleReviewStatus = (c) => {
    let r = handleBankSlip(c);

    if (r?.review_status === true) {
      return "Verified";
    }
    if (r?.review_status === false) {
      return "Rejected";
    }
    if (r?.review_status === null) {
      return "Pending";
    }
    // console.log(c);
  };

  /**
   * @description handle refersh table
   */
  const handleRefershTable = async () => {
    console.log("refersh clicked");
    setOffset(0);
    if (currentRole === roles.WP_DOC_CHECKER) {
      // getTotalAssignedPermits({ fetchPolicy: "network-only" });
      getAllAssignedPermits({ fetchPolicy: "network-only" });
      getAllApprovedPermits({ fetchPolicy: "network-only" });
      getAllRejectedPermits({ fetchPolicy: "network-only" });
    } else {
      setWorkPermits([]);
      await getDataFromServer({
        variables: { limit: limit, offset: 0 * limit },
        fetchPolicy: "network-only",
      });
    }
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <pre
        style={{
          fontSize: "10px",
        }}
      >
        <table className="w-11/12 mx-auto capitalize">
          <thead className="bg-[#F0F6FD]">
            <tr>
              <th className="text-left text-N60 p-2 py-4">filename</th>
              <th className="text-left text-N60 p-2 py-4">uploaded date</th>
              <th className="text-left text-N60 p-2 py-4">title</th>
              <th className="text-left text-N60 p-2 py-4">assigned to</th>
            </tr>
          </thead>
          <tbody className="alternate-bg text-base">
            {row?.original?.expatriate_work_permits[0]?.expatriate_work_permit_documents?.map(
              (item) => (
                <tr>
                  <td className="px-2 py-4 font-semibold">{item.title}</td>
                  <td className="px-2 py-4 font-semibold">
                    <Moment format="YYYY/MM/DD">{item?.created_at}</Moment>
                  </td>
                  <td className="px-2 py-4 font-semibold">
                    <span className="bg-Prime90/40 border px-2 py-1 rounded border-prime50 text-primary">
                      {item?.properties?.name?.substring(0, 25)}
                    </span>
                  </td>
                  <td className="px-2 py-4 font-semibold">
                    <div className="flex flex-col text-xs">
                      {row?.original?.expatriate_work_permits[0]?.assigned_workpermit_reviewers?.map(
                        (i) => (
                          <span>{i?.employee?.user_info?.name}</span>
                        )
                      )}
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>

        {/* <code>{JSON.stringify({ values: row?.original?.work_permit_request_documents }, null, 2)}</code> */}
      </pre>
    ),
    []
  );

  const handlePreviousPage = () => {
    if (
      currentRole === roles.WP_DOC_CHECKER ||
      currentRole === roles.WP_FINANCE
    ) {
      if (selectedTab === "pending") {
        setPendingOffset(pending_offset - 1);
      }
      if (selectedTab === "approved") {
        setApprovedOffset(approved_offset - 1);
      }
      if (selectedTab === "rejected") {
        setRejectedOffset(rejected_offset - 1);
      }
    }
  };

  const handleNextPage = () => {
    // setOffset(offset + 1); //the first offset
    if (
      currentRole === roles.WP_DOC_CHECKER ||
      currentRole === roles.WP_FINANCE
    ) {
      if (selectedTab === "pending") {
        setPendingOffset(pending_offset + 1);
      }
      if (selectedTab === "approved") {
        setApprovedOffset(approved_offset + 1);
      }
      if (selectedTab === "rejected") {
        setRejectedOffset(rejected_offset + 1);
      }
    }
  };

  const handlePageChange = (newOffset) => {
    if (
      currentRole === roles.WP_DOC_CHECKER ||
      currentRole === roles.WP_FINANCE
    ) {
      if (selectedTab === "pending") {
        setPendingOffset(newOffset - 1);
      }
      if (selectedTab === "approved") {
        setApprovedOffset(newOffset - 1);
      }
      if (selectedTab === "rejected") {
        setRejectedOffset(newOffset - 1);
      }
    }
  };

  const handleSearch = (r) => {
    console.log("search value", r);
  };

  const handleSelectedFilterOption = (_value) => {
    setSelectedValue(_value);
  };

  const convertDateString = (dateStr) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const date = new Date(dateStr);

    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  // ==============================================DOCUMENT CHECKER==================================================
  /**
   * @description get permit for assigned user
   * @access doc:Checker | finance
   */
  const [getAllAssignedPermits, { loading: permitLoading }] = useLazyQuery(
    GET_ALL_ASSIGNED_WORK_PERMITS,
    {
      variables: {
        limit: Number(pending_limit),
        offset: pending_offset * Number(pending_limit),
        permit: WORK_PERMIT_TYPES.CANCELLATION,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setPendingList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );

  /**
   * @description get all approved permits
   */
  const [getAllApprovedPermits, { loading: approvedLoading }] = useLazyQuery(
    DOCUMENT_ALL_APPROVED_WORK_PERMITS,
    {
      variables: {
        limit: Number(approved_limit),
        offset: approved_offset * Number(approved_limit),
        checkerStatus: true,
        permit: WORK_PERMIT_TYPES.CANCELLATION,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setApprovedList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );

  /**
   * @description get all rejected permits
   */
  const [getAllRejectedPermits, { loading: rejectedLoading }] = useLazyQuery(
    DOCUMENT_ALL_APPROVED_WORK_PERMITS,
    {
      variables: {
        limit: Number(rejected_limit),
        offset: rejected_offset * Number(rejected_limit),
        checkerStatus: false,
        permit: WORK_PERMIT_TYPES.CANCELLATION,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        console.log("rejected:", d?.assigned_workpermit_reviewers);
        setRejectedList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );

  /**
   * @description get Total count for assigned user
   * @access DOC:checker | finance
   */
  const [getTotalAssignedPermits, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        permit: WORK_PERMIT_TYPES.CANCELLATION,
      },
      onCompleted: (d) => {
        setCountPending(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );
  // Count Approved permit (DOC Checker)
  const [getTotalAssignedPermitsApproved, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        checkerStatus: true,
        permit: WORK_PERMIT_TYPES.CANCELLATION,
      },
      onCompleted: (d) => {
        setCountApproval(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );
  //Count Rejected permits (DOC Checker)
  const [getTotalAssignedPermitsRejected, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        checkerStatus: false,
        permit: WORK_PERMIT_TYPES.CANCELLATION,
      },
      onCompleted: (d) => {
        setCountRejected(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );

  // ===================================================END DOCUMENT CHECKER===========================================

  // =======================================FIANCE========================
  /**
   * @description verifi document Bank
   */
  const [verify_doc, { fin_data, loading: signInLoading }] = useMutation(
    VERIFY_EXPATRIATE_DOCUMENT,
    {
      onError(err) {
        const error = `${err}`.split(":").reverse()[0];
        console.log(error.trim());
      },
      onCompleted(d) {
        console.log(d);
      },
    }
  );

  const handleReject = async (w) => {
    console.log("rejecte document", w, modalData);
    await verify_doc({
      variables: {
        id: modalData?.id,
        review_status: false,
        review_note: w?.review_note_doc,
      },
    }).then(() => {
      // toast.SuccessToast('Finance Docuemnt Verified Successfully')
      toast.warn("Finance Document Rejected!");
      handleClose();
    });
  };

  const handleVerify = async () => {
    console.log("Finance Document Verified Successfully", modalData?.id);
    await verify_doc({
      variables: { id: modalData?.id, review_status: true },
    }).then(() => {
      handleClose();
      toast.success("Finance Document Verified Successfully");
    });
  };

  // ---------------------------------------------------------------SEARCH----------------------------------
  const handlePageLimit = (_limit) => {
    console.log(_limit);
    // setLimit(Number(_limit));
    if (currentRole === roles.WP_DOC_CHECKER) {
      if (selectedTab === "pending") {
        setPendingLimit(_limit);
      }
      if (selectedTab === "approval") {
        setApprovedLimit(_limit);
      }
      if (selectedTab === "rejected") {
        setRejectedLimit(_limit);
      }
    }
  };

  const openModalEMR = (e) => {
    console.log("cancelation: ", e);
    setShowEMRModal((prev) => !prev);
    setModalData(e);
    // setShowModalEMRID((prev) => !prev);
  };

  const handleCancelModal = () => {
    setShowEMRModal((prev) => !prev);
    setModalData([]);
  };

  const handleGenerateURL = (_data) => {
    console.log(_data);
    generatePaper({
      variables: {
        emr: modalData,
        job: _data?.job,
        expiryDate: _data?.expiryDate,
        requestedDate: _data?.requestedDate,
      },
      onCompleted: (data) => {
        console.log(data);
        reset();
        setShowEMRModal(false);
        window.open(`https://${data?.generateCancelDocument?.url}`);
      },
      onError: (er) => {
        toast.error(er?.message);
      },
    });
  };

  const handleAssign = (c) => {
    console.log(c);
    // Set loading state for the specific row
    setLoadingRows((prevLoadingRows) => [...prevLoadingRows, c]);
    console.log("rows:", loadingRows);
    console.log("rows:___", loadingRows.includes(c));
    console.log(setLoadingRows((prevLoadingRows) => [...prevLoadingRows, c]));

    assign({ variables: { id: c } }).then((re) => {
      if (re.errors) {
        toast.error("Permit not Assigned");
      } else {
        // toast.success("Assigned for Fnance and Doc checker");
        getDataFromServer({ fetchPolicy: "network-only" });
        setLoadingRows((prevLoadingRows) =>
          prevLoadingRows.filter((rowId) => rowId !== c)
        );
      }
    });
  };

  const handleGenerateLetter = (data) => {
    
    console.log("cancel", data);
    let _result = {
      ...data,
      expiryDate: convertDateString(data.expiryDate),
      requestedDate: convertDateString(data.requestedDate),
    };
    console.log(_result);
    handleGenerateURL(_result);
  };

  /**
   * @description EMR pagination
   */
  const handlePreviousPageEMR = () => {
    if (approved_offset > 0) {
      setApprovedOffset(approved_offset - 1);
    }
  };

  const handleNextPageEMR = () => {
    setApprovedOffset(approved_offset + 1); //the first offset
  };

  const handlePageChangeEMR = (newOffset) => {
    setApprovedOffset(newOffset - 1);
  };

  const handlePageLimitEMR = (_limit) => {
    setApprovedLimit(Number(_limit));
  };

  const handleRefershTableEMR = () => {
    getVerifiedDocuments({ fetchPolicy: "network-only" });
    setApprovedLimit(10);
    setApprovedOffset(0);
  };

  const resetFilter = () => {
    reset();
    if (currentRole === roles.WP_EMR_GENERATOR) {
      getVerifiedDocuments();
    }
    if (currentRole === roles.WP_DOC_CHECKER) {
      getAllAssignedPermits();
      getAllApprovedPermits();
      getAllRejectedPermits();
    }
    if (currentRole === roles.WP_DESK_HANDLER) {
      getDataFromServer();
      getNotAssignedRenewalPermit();
      countExpat();
      countNotAssigned();
    }
  };

  const handlePageLimitMAIN = (_limit) => {
    if (currentTab === "not_assigned") {
      setPendingLimit(Number(_limit));
    } else {
      setApprovedLimit(Number(_limit));
    }
  };
  const handlePreviousPageMAIN = () => {
    if (currentTab === "assigned") {
      if (approved_offset > 0) {
        setApprovedOffset(approved_offset - 1);
      }
    } else {
      if (pending_offset > 0) {
        setPendingOffset(pending_offset - 1);
      }
    }
  };

  const handleNextPageMAIN = () => {
    if (currentTab === "not_assigned") {
      setPendingOffset(approved_offset + 1); //the first offset
    } else {
      setApprovedOffset(pending_offset + 1); //the first offset
    }
  };

  const handlePageChangeMAIN = (newOffset) => {
    if (currentTab === "not_assigned") {
      setPendingOffset(newOffset - 1);
    } else {
      setApprovedOffset(newOffset - 1);
    }
  };

  /**
   * @description search filter for EMR
   * @param {*} e
   */
  const handleEMRGeneratorHandlerFilter = (e) => {
    if (e) {
      setLoading(true);
      searchPermitEMR({
        variables: {
          emr: e,
          permit: WORK_PERMIT_TYPES.CANCELLATION,
        },
      });
    }
  };

  /**
   * @description search filter for Document checker
   * @param {*} e
   */
  const handleDocumentHandlerFilter = (e) => {
    setSelectedTab("pending");
    if (e) {
      searchPermitDocumentChecker({
        variables: {
          searchVal: e?.filterValue,
          permit: WORK_PERMIT_TYPES.CANCELLATION,
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
    }
  };

  const [handleDeskHandlerPermitFilter, {}] = useLazyQuery(
    SEARCH_WORK_PERMITS_FILTER,
    {
      variables: {
        permit: WORK_PERMIT_TYPES.CANCELLATION,
      },
      onCompleted: (res) => {
        setPendingList(res?.expatriate_work_permits);
      },
      onError: (er) => console.log(er),
    }
  );


   //search for Finance (filter)
   const [searchFinance, {}] = useLazyQuery(
    SEARCH_EXPATIATES_PERMITS_FILTER_DOCUMENT_CHECKER_FINANCE,
    {
      onCompleted: (d) => {
        setPendingList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => console.log(er),
    }
  );


  const handleFinanceFilter = (e) => {
    console.log("filter: ",e);
    setSelectedTab("pending");
    if (e) {
      searchFinance({
        variables: {
          searchVal: e?.filterValue,
          permit: WORK_PERMIT_TYPES.CANCELLATION,
          employeeID:  JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
    }
  };

  const handleFilter = (e) => {
    console.log("filter", e);
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        // Update table for DESK Handler
        // handleDeskHandlerFilter(e);
        setCurrentTab("not_assigned");
        handleDeskHandlerPermitFilter({
          variables: {
            searchValue: e?.filterValue,
          },
        });
        // console.log("desk handler filter");
        break;
      case roles.WP_DOC_CHECKER:
        // Update table for Document Checker
        handleDocumentHandlerFilter(e);
        console.log("doc checker filter");
        break;
      case roles.WP_FINANCE:
        // Update table for Finance Checker
        handleFinanceFilter(e);
        console.log("finance filter");
        break;
      case roles.WP_EMR_GENERATOR:
        // Update table for EMR Generator Checker
        handleEMRGeneratorHandlerFilter(e?.filterValue);
        console.log("emr generator filter");
        break;

      default:
        break;
    }
  };

  const openModal = (e) => {
    console.log("slip", e?.row?.original?.expatriate_work_permit);
    setShowModal(true);
    let files = handleBankSlip(e?.row?.original?.expatriate_work_permit);
    setModalData({
      files: files?.files,
      id: files?.id,
      organization:
        e?.row?.original?.expatriate_work_permit?.organization?.name,
      name:
        e.row.original?.expatriate_work_permit?.expatriate?.first_name +
        " " +
        e?.row?.original?.expatriate_work_permit?.expatriate?.father_name,
      reference_number:
        e?.row?.original?.expatriate_work_permit?.reference_number,
    });
  };

  const handleRejectModal = () => {
    setRejectModal((prev) => !prev);
  };

  return (
    <div className="">
      <FilterBox
        placeholder={`${
          currentRole === roles.WP_EMR_GENERATOR
            ? "EMR Number"
            : currentRole === roles.WP_FINANCE
            ? "Reference Number, Tin Number and passport"
            : currentRole === roles.WP_DOC_CHECKER
            ? "Tin Number, Passport Number or First Name"
            : "First Name and Passport Number"
        }`}
        handleReset={resetFilter}
        handleFilterData={handleFilter}
      />

      {/* {currentRole === roles.WP_DESK_HANDLER && ( */}
      {currentRole === roles.WP_DESK_HANDLER && (
        <>
          <div className="mb-5 text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-200">
            <ul className="flex flex-wrap -mb-px">
              <li className="mr-2">
                <button
                  onClick={() => setCurrentTab("not_assigned")}
                  className={`${
                    currentTab === "not_assigned"
                      ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : "hover:text-gray-600 hover:border-gray-300 border-transparent"
                  } inline-block p-4 border-b-2 rounded-t-lg`}
                >
                  Not Assigned
                </button>
              </li>
              <li className="mr-2">
                <button
                  onClick={() => setCurrentTab("assigned")}
                  className={`${
                    currentTab === "assigned"
                      ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : "hover:text-gray-600 hover:border-gray-300 border-transparent"
                  } inline-block p-4 border-b-2 rounded-t-lg`}
                  aria-current="page"
                >
                  Assigned
                </button>
              </li>
            </ul>
          </div>
          <Table
            columns={FailedColumns.failedDeskhandlerColumn(
              navigate,
              handleAssign,
              loadingRows,
              handleReviewStatus
            )}
            tableData={
              currentTab === "not_assigned"
                ? pendingCancellationList
                : approvedCancellationList
            }
            loading={loading}
            error={error}
            offset={
              currentTab === "not_assigned" ? pending_offset : approved_offset
            }
            limit={
              currentTab === "not_assigned" ? pending_limit : approved_limit
            }
            filterOptions={filterOptionTable}
            totalPages={
              currentTab === "not_assigned" ? pendingCount : approvalCount
            }
            handleNextPage={handleNextPageMAIN}
            handlePageChange={handlePageChangeMAIN}
            handlePreviousPage={handlePreviousPageMAIN}
            handleRefersh={handleRefershTable}
            handlePageLimit={handlePageLimitMAIN}
            onSearchChange={handleSearch}
            onFilterChange={handleSelectedFilterOption}
            renderRowSubComponent={renderRowSubComponent}
            title={"Cancellation Request:  Work Permit Applicants"}
            headerClassess="bg-primary"
            pageCount={pageCount}
          />
        </>
      )}

      {currentRole === roles.WP_EMR_GENERATOR && (
        <Table
          columns={FailedColumns.failedDeskHandlerEMRColumn(
            navigate,
            openModalEMR,
            handleReviewStatus
          )}
          tableData={approvedCancellationList}
          loading={loading}
          error={error}
          offset={approved_offset}
          limit={approved_limit}
          filterOptions={filterOptionTable}
          totalPages={approvalCount}
          handleNextPage={handleNextPageEMR}
          handlePageChange={handlePageChangeEMR}
          handlePreviousPage={handlePreviousPageEMR}
          handleRefersh={handleRefershTableEMR}
          handlePageLimit={handlePageLimitEMR}
          onSearchChange={handleSearch}
          onFilterChange={handleSelectedFilterOption}
          renderRowSubComponent={renderRowSubComponent}
          title={"Cancellation Request:  Work Permit Applicants"}
          headerClassess="bg-primary"
          pageCount={pageCount}
        />
      )}

      {[roles.WP_DOC_CHECKER, roles.WP_FINANCE].includes(currentRole) && (
        <>
          <Can I={"action"} a={"filterTab"}>
            {() => (
              <div
                className="my-4 inline-flex rounded-md shadow-sm"
                role="group"
              >
                <button
                  type="button"
                  onClick={() => setSelectedTab("pending")}
                  className={`${
                    selectedTab === "pending"
                      ? "bg-yellow30 text-white hover:opacity-75 border-yellow40 "
                      : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  } px-4 py-2 text-sm font-medium text-gray-900 border  rounded-l-lg `}
                >
                  Pending
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("rejected")}
                  className={`${
                    selectedTab === "rejected"
                      ? "bg-red30 text-white hover:opacity-75 border-red20 "
                      : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  } px-4 py-2 text-sm font-medium text-gray-900 border `}
                >
                  Rejected
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("approved")}
                  className={`${
                    selectedTab === "approved"
                      ? "bg-green30 text-white hover:opacity-75 border-green40 "
                      : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  } px-4 py-2 text-sm font-medium text-gray-900 border rounded-r-lg `}
                >
                  Approved
                </button>
              </div>
            )}
          </Can>

          <Table
            columns={
              currentRole === roles.WP_DOC_CHECKER
                ? FailedColumns.failedDocumentCheckerColumn(
                    navigate,
                    handleAssign,
                    loadingRows,
                    handleReviewStatus
                  )
                : FailedColumns.failedFinanceColumn(handleReviewStatus, openModal)
            }
            tableData={
              selectedTab === "pending"
                ? pendingCancellationList
                : selectedTab === "approved"
                ? approvedCancellationList
                : rejectedCancellationList
            }
            loading={loading}
            offset={
              selectedTab === "pending"
                ? pending_offset
                : selectedTab === "approved"
                ? approved_offset
                : rejected_offset
            }
            filterOptions={filterOptionTable}
            limit={
              selectedTab === "pending"
                ? pending_limit
                : selectedTab === "approved"
                ? approved_limit
                : rejected_limit
            }
            error={error}
            handleRefersh={handleRefershTable}
            totalPages={
              selectedTab === "pending"
                ? pendingCount
                : selectedTab === "approved"
                ? approvalCount
                : rejectedCount
            }
            handleNextPage={handleNextPage}
            handlePageChange={handlePageChange}
            handlePreviousPage={handlePreviousPage}
            handlePageLimit={handlePageLimit}
            onFilterChange={handleSelectedFilterOption}
            renderRowSubComponent={renderRowSubComponent}
            title={"Applicant:  Renewal Work Permit Applicants"}
            headerClassess="bg-primary"
            pageCount={pageCount}
          />
        </>
      )}

      <div className="fixed top-0 left-0 right-0 overflow-hidden">
        {show ? (
          <BlackListModal id={queryName} handleClick={handlePopUp} />
        ) : (
          ""
        )}
      </div>

      {/* {showModal && (
        <FinanceDocument
          handleReject={handleReject}
          handleVerify={handleVerify}
          data={modalData}
          handleClick={handleClose}
        />
      )} */}

      {showEMRModal && (
        <div
          id="defaultModal"
          className="w-full h-screen bg-black/15 fixed backdrop-blur-md flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0"
        >
          <div class="relative w-full max-w-2xl max-h-full">
            <div class="relative bg-white rounded-lg shadow">
              <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-300">
                <h3 class="text-xl font-semibold text-gray-900">
                  Cancellation Letter Generate
                </h3>
                <button
                  onClick={handleCancelModal}
                  type="button"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    class="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
              <FormProvider
                onSubmithandler={handleSubmit(handleGenerateLetter)}
              >
                <div class="p-6 space-y-6">
                  <div className="grid grid-cols-2 gap-4">
                    <CInputField
                      name="emr_number"
                      register={register}
                      errors={errors}
                      defaultValue={modalData}
                      disabled
                      type="text"
                      label="EMR Number"
                      placeholder=""
                    />
                    <CInputField
                      name="job"
                      register={register}
                      errors={errors}
                      validation={{
                        required: true,
                      }}
                      type="text"
                      label="Job"
                    />
                    <CInputField
                      name="expiryDate"
                      register={register}
                      errors={errors}
                      // validation={{
                      //   required: true,
                      // }}
                      type="date"
                      label="Expirey Date"
                    />
                    <CInputField
                      name="requestedDate"
                      register={register}
                      errors={errors}
                      // validation={{
                      //   required: true,
                      // }}
                      type="date"
                      label="Requested Date"
                    />
                  </div>
                </div>
                <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-300">
                  <CButton
                    data-modal-hide="defaultModal"
                    type="submit"
                    // handleClick={handleGenerateLetter}
                    btnLabel={"Generate"}
                    loading={letterLoading}
                    classes="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  />
                  <button
                    data-modal-hide="defaultModal"
                    type="button"
                    onClick={handleCancelModal}
                    className="text-gray-500 mt-5 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    Cancel
                  </button>
                </div>
              </FormProvider>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <DocumentViewerDrawer
          selectedDocument={modalData}
          handleVerify={handleVerify}
          handleRejection={handleRejectModal}
          handleCloseDrawer={() => setShowModal(false)}
        />
      )}

      {rejectedModal && (
        <RejectModal
          setShowDocumentModal={setRejectModal}
          title={"Reject Work permit Bank Slip"}
          handleRejectDocument={handleReject}
        />
      )}
    </div>
  );
};

export default authorize("view")(Cancelled);
