import React from "react";
import Navbar from "../common/Navbar";
import { useNavigate } from "react-router-dom";
import { useToaster } from "../../hooks/useToaster";
import { Can } from "../../permission/Can";
import { useLocalStore } from "../../store";
import { useLazyQuery } from "@apollo/client";
import { USER_INFO } from "../../graph-query/queries";
import { useEffect } from "react";
import { updateRole } from "../../permission/Ability";
import { MdNotifications } from "react-icons/md";
import Notification from "./Notification";

const Nav = ({ Avatar, optionOrg, name, userType }) => {
  // console.log(name);
  const [isOpen, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);

  const router = useNavigate();

  const {
    setData,
    setAssignedWorkPermits,
    setOrganizationDashboardData,
    setOrganizationWorkPermits,
  } = useLocalStore();

  const [userInfo, {}] = useLazyQuery(USER_INFO, {
    variables: { id: JSON.parse(localStorage.getItem("work-permit"))?.userID },
    onCompleted: (e) => {
      // console.log(e);
      setUser(e?.public_users[0]);
    },
  });
  useEffect(() => {
    userInfo();
  }, []);

  const orgList = [];
  if (optionOrg) {
    orgList = name?.map((i) => {
      return {
        name: i?.name,
        id: i?.id,
      };
    });
  }
  // console.log(orgList);

  const navigate = useNavigate();
  const toast = useToaster();

  const handleDropDown = () => {
    setOpen(!isOpen);
  };

  const handelLogout = () => {
    localStorage.removeItem("work-permit");
    localStorage.removeItem("expatriate");
    localStorage.removeItem("currentOrg");
    setData("expatriate", {});
    setAssignedWorkPermits([]);
    setOrganizationDashboardData([]);
    setOrganizationWorkPermits([]);
    updateRole();
    toast.InfoToast("Logout Successfull!");
    navigate("/login");
  };

  return (
    <Navbar orgList={orgList} dropDown={optionOrg} custom="bg-white shadow">
      <Can I="action" a="notification">
        {() => (
          <Notification />
        )}
      </Can>

      <div className="flex items-center space-x-2">
        <div className="hidden md:block">
         { <h3>{name[0]?.user_info ? name[0]?.user_info?.name : user?.name}</h3>}
          <p className="text-xs text-N50 font-medium">{userType}</p>
        </div>

        <div className="relative inline-block text-left">
          <div>
            <button
              onClick={handleDropDown}
              className="flex justify-between items-center p-4 space-x-2 bg-gray-50 hover:bg-gray-100 rounded-full"
            >
              <div
                data-dropdown-toggle="dropdown"
                className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full"
              >
                <span className="font-medium text-gray-600 dark:text-gray-300">
                  {name[0]?.user_info
                    ? name[0]?.user_info?.name?.substring(0, 2)?.toUpperCase()
                    : user?.name?.substring(0, 2)?.toUpperCase()}
                </span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3 md:w-4 md:h-4 fill-current pt-1 md:ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
              </svg>
            </button>

            {isOpen && (
              <ul className="dropdown-menu absolute right-0 w-48 mt-2 bg-white shadow-lg rounded-b">
                <Can a={"report"} I={"view"}>
                  {() => (
                    <li>
                      <a
                        href="#"
                        className="flex px-4 py-2 w-full hover:bg-gray-50"
                      >
                        Report
                      </a>
                    </li>
                  )}
                </Can>
                <Can a="orgProfile" I="view">
                  {() => (
                    <li onClick={() => navigate("/profile")}>
                      <button
                        type="button"
                        className="flex w-full px-4 py-2 hover:bg-gray-50"
                      >
                        Profile
                      </button>
                    </li>
                  )}
                </Can>
                <Can a="workspace" I={"view"}>
                  {() => (
                    <li>
                      <button
                        type="button"
                        onClick={() => router("/workspace")}
                        className="flex w-full px-4 py-2 hover:bg-gray-50"
                      >
                        WorkStation
                      </button>
                    </li>
                  )}
                </Can>
                <Can a="createProfile" I="view">
                  {() => (
                    <li
                      className="cursor-pointer"
                      onClick={() => router("/create-organization-profile")}
                    >
                      <a className="flex px-4 py-2 hover:bg-gray-50">
                        Create New Organization
                      </a>
                    </li>
                  )}
                </Can>
                <li>
                  <button
                    type="button"
                    onClick={() => router("/organization/change-password")}
                    className="flex w-full px-4 py-2 hover:bg-gray-50"
                  >
                    Change Password
                  </button>
                </li>
                <hr className="my-2" />
                <li>
                  <button
                    type="button"
                    onClick={handelLogout}
                    className="flex w-full px-4 py-2 hover:bg-gray-50"
                  >
                    Logout
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </Navbar>
  );
};

Nav.defaultProps = {
  optionOrg: false,
  orgList: [],
};
export default Nav;
